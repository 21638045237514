@import "./mixins/variables.scss";
@import "./mixins/home.scss";
@import "./mixins/about.scss";
@import "./mixins/slogan.scss";
@import "./mixins/illustration.scss";
@import "./mixins/svgIcons.scss";

@import "./mixins/media.scss";
@import "./mixins/emphasis.scss";
@import "./mixins/studio-a.scss";
@import "./mixins/projects.scss";

@import "./mixins/references.scss";
@import "./mixins/contact.scss";
@import "./mixins/footer.scss";

::-webkit-scrollbar {
  background-color: var(--background-color);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--text-color);
  border-radius: 10px;
}

.main {
  overflow-x: hidden !important;
  scroll-behavior: smooth;
  // -webkit-overflow-scrolling: touch;
  white-space: pre-line;
  user-select: none;
  font-size: 1.375rem;
  background-color: var(--background-color);
  color: var(--text-color);
  transition: color $transitionSpeed, background-color $transitionSpeed;

  section:not(#home) {
    scroll-margin: 113px 0px 0 0;
  }

  .title {
    font-size: 2.5rem;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .text-big {
    font-size: 3.75rem;
  }
  .text-medium {
    font-size: 1.875rem;
  }

  .text-small {
    font-size: 1.375rem;
    transition: opacity $transitionSpeed;
  }

  .white {
    color: white !important;
  }

  .divider {
    width: 122px;
    height: 3px;
    background-color: var(--text-color);
    transition: background-color $transitionSpeed;
    margin: 10px 0px 40px;
  }
  .container {
    margin: 96px;
    max-width: calc(100% - (2 * 96px));

    .row {
      margin: 0;
    }
  }

  .icon {
    width: 48px;
    height: 48px;
    color: var(--text-color);
  }

  .col {
    padding: 0;
  }

  .divider-animation {
    cursor: pointer;
  }
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .main {
    overflow: hidden;
    .title {
      font-size: 1.875rem;
    }

    .text-big {
      font-size: 2.5rem;
    }
    .text-medium {
      font-size: 1rem;
    }

    .text-small {
      font-size: 0.938rem;
    }

    .container {
      margin: 96px 0px;
      padding: 0px 24px;
      max-width: 100%;
    }
  }
}
