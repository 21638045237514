.Media {
  .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  p {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  .Media {
    .right {
      height: 25vh;
      margin-top: 2vh;
    }
  }
}
