.arrow {
  width: 24px;
  height: 24px;
  fill: var(--text-color);

  &.arrow-up {
    transform: rotate(180deg);
  }

  &.active {
    fill: var(--color-primary);
  }
}
