.about {
  .image-holder {
    display: flex;
    justify-content: center;

    img {
      width: 70%;
      object-fit: contain;
      position: relative;
    }
  }
}
