.slogan {
  text-align: center;

  .row {
    &:hover {
      .divider-animation {
        &:before {
          width: 88%;
          background-color: $colorPrimary;
        }

        &:after {
          width: 15%;
          background-color: $colorPrimary;
        }
      }
      .contact {
        color: $colorPrimary;
      }
    }
    .divider-animation {
      position: relative;

      &:after,
      &:before {
        content: "";
        position: absolute;
        z-index: 1;
        height: 3px;
        width: 100px;
        top: 50%;
        transform: translateY(-50%);
        transition: width 0.2s, color 0.1s;
        background-color: white;
      }

      &:before {
        left: 0;
      }

      &:after {
        left: 88%;
      }
    }

    .contact {
      display: flex;
      align-self: center;
      justify-content: space-evenly;

      //   p {
      //     align-self: center;
      //   }
    }
  }
}

@media screen and (max-width: 768px) {
  .slogan {
    .row {
      .divider-animation {
        &:after,
        &:before {
          top: 110% !important;
          width: 10% !important;
        }

        &:after {
          content: none;
        }
      }
    }
  }
}
