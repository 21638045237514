.Home {
  // background-image: url("../../../images/Background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 100vw;
  height: 100vh;

  .logo-v1 {
    width: 70%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .st0 {
      fill: #fff;
      stroke: none;
    }
  }

  .text-big {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -30%);
  }

  a {
    color: transparent;
  }

  .circle-arrow {
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 50px;
    text-align: center;
    color: white;
    transition: color $transitionSpeed;

    &:hover {
      color: $colorPrimary;
    }
  }
}

#home {
  width: 100vw;
}
