.circle-arrow {
  width: 24px;
  height: 24px;

  path {
    fill: #fff;
    transition: fill var(--transition-speed), d var(--transition-speed);
  }

  &.circle-arrow-up {
    transform: rotate(180deg);
  }

  &.circle-arrow-left {
    transform: rotate(90deg);
  }

  &.circle-arrow-right {
    transform: rotate(270deg);
  }

  &.active,
  &:hover {
    path {
      fill: var(--color-primary);
    }
    #arrow {
      d: path("M34.54,33.34 28.59,39.12 28.59,13.52 25.89,13.52 25.89,39.12 19.95,33.34 18.06,35.27 27.24,44.2 36.42,35.27z");
    }
  }
}
