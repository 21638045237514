.brands-wrapper {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  //   max-height: 10vh;
  padding: 1%;
  background-color: white;
  flex-wrap: wrap;
  svg,
  img {
    height: 5vh;
    width: auto;
  }
}

@keyframes slider {
  0% {
    transform: translate3d(0%, 0, 0);
  }

  100% {
    transform: translate3d(-200%);
  }
}

@media screen and(max-width: 768px) {
  .brands-wrapper {
    display: none;
    svg,
    img {
      height: 4vh;
      margin-bottom: 3%;
    }
  }
}
