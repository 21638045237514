.circle-cross {
  fill: var(--text-color);
  transform: rotateZ(0deg);
  transition: ease-in-out var(--transition-speed);

  &:hover {
    fill: var(--color-primary);
    transform: rotateZ(90deg);
  }
}
