@import "~bootstrap-icons/font/bootstrap-icons";

@font-face {
  font-family: CircularXX;
  src: local("CircularXX"), url("./font/CircularXX-Regular.otf") format("opentype");
}

@font-face {
  font-family: CircularXX;
  font-weight: bold;
  src: local("CircularXX"), url("./font/CircularXX-Medium.otf") format("opentype");
}

@font-face {
  font-family: CircularXX;
  font-weight: lighter;
  src: local("CircularXX"), url("./font/CircularXX-Light.otf") format("opentype");
}

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  margin: 0;
  font-family: CircularXX, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
