.references {
  .bi {
    font-size: 2rem;
    top: 50%;
    transform: translateX(50%) translateY(50%);
    position: absolute;
    transition: color $transitionSpeed;
    cursor: pointer;
    z-index: 2;
    &:hover,
    &:focus {
      color: $colorPrimary;
    }

    &.bi-next {
      left: 0;
    }
    &.bi-prev {
      right: 0;
    }
  }

  .gallery {
    height: 60vh;
    transform-style: preserve-3d;
    perspective: 50em;

    .active,
    .prev,
    .next,
    .inactive {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: transform $transitionSpeed;
      height: 100%;
      width: 66%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }

    .active {
      transform: translate3d(-50%, -50%, 0px);
    }

    .next {
      transform: translate3d(-25%, -50%, -200px);
    }

    .prev {
      transform: translate3d(-75%, -50%, -200px);
    }

    .inactive {
      transform: translate3d(-50%, -50%, -400px);
    }

    .image-description {
      opacity: 0;
      display: none;
      transition: opacity $transitionSpeed;
      align-items: flex-end;
      transform: translate3d(-50%, -42%, 0);

      &.active {
        opacity: 1;
        display: flex;
      }
    }
  }

  .popup-gallery {
    display: none;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    z-index: 99999;
    position: fixed;
    background-color: var(--background-color);
    bottom: 0;
    left: 0px;
    transition: opacity $transitionSpeed;

    .container {
      position: absolute;
      bottom: 0;
      top: 0;

      .row {
        height: 100%;

        .main-image {
          width: 100%;
          height: 80%;
          opacity: 1;
          position: absolute;
          .active,
          .next,
          .prev,
          .inactive {
            opacity: 0;
            display: none;
            width: 100%;
            height: 95%;
            position: absolute;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            transition: opacity $transitionSpeed;
          }

          .active {
            opacity: 1;
            display: block;
          }

          .image-description {
            text-align: center;
            align-self: flex-end;
            height: 5%;
            top: 95%;
          }
        }

        .image-bar {
          height: 20%;
          position: absolute;
          bottom: 0;
          width: 100%;

          .image-preview {
            height: 100%;
            max-width: 19%;
            cursor: pointer;
            margin-inline: 0.5%;
            transition: outline $transitionSpeed;
            &.active {
              outline: solid 0.2rem $colorPrimary;
            }
          }
        }
      }
      .gallery-control {
        top: 0;
        right: 0;
        transform: translate(0, 0);
      }
    }

    &.active {
      display: block;
      opacity: 1;
    }
  }
}

@media only screen and(max-width: 768px) {
  .references {
    .bi {
      top: 100%;
      transform: translate(0, 0);
    }
    &.bi-next {
    }
  }
}
