.studio-a {
  .row {
    &:hover {
      .contact {
        color: $colorPrimary;
        .divider-animation:after {
          width: 50%;
          background-color: $colorPrimary;
        }
      }
    }
    .divider-animation {
      // position: absolute;

      &:after {
        left: 0;
        content: "";
        position: absolute;
        z-index: 1;
        height: 3px;
        width: 20%;
        top: 50%;
        transform: translateY(-50%);
        transition: width 0.2s, color 0.1s;
        background-color: white;
      }
    }

    .contact > p {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 100%;
      text-align: right;
    }
  }
}

@media screen and (max-width: 768px) {
  .studio-a {
    .row {
      .contact {
        color: $colorPrimary;
        .divider-animation:after {
          width: 30%;
          background-color: $colorPrimary;
        }
      }
    }
    &:hover {
      .contact {
        color: $colorPrimary;
        .divider-animation:after {
          width: 30%;
          background-color: $colorPrimary;
        }
      }
    }
  }
}
