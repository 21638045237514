.emphasis {
  .row {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
  }

  .emphase {
    max-width: 13%;

    .st8 {
      fill: var(--text-color) !important;
      transition: fill $transitionSpeed;
    }

    .emphasisText {
      text-align: center;
      margin-top: 37px;
      transition: color $transitionSpeed;
    }

    &:hover {
      .st8 {
        fill: $colorPrimary;
      }

      .emphasisText {
        color: $colorPrimary;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .emphasis {
    .emphase {
      max-width: 51%;
      left: 50%;
      transform: translate(-50%, 0);
      margin-bottom: 24px;

      .emphasisText {
        color: $colorPrimary;
      }
    }
  }
}
