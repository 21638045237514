#illustration {
  height: 50vh;

  svg {
    width: 80%;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }
  .st0 {
    fill: url(#SVGID_1_);
  }
  .st1 {
    fill: #003dc2;
  }
  .st2 {
    fill: url(#SVGID_2_);
  }
  .st3 {
    fill: #f59147;
  }
  .st4 {
    fill: #e5365e;
  }
  .st5 {
    fill: none;
    stroke: #003dc2;
    stroke-width: 2.7;
    stroke-miterlimit: 10;
  }
  .st6 {
    fill: #ffffff;
    stroke: #5ae0c0;
    stroke-width: 2.7;
    stroke-miterlimit: 10;
  }
  .st7 {
    fill: none;
    stroke: #5ae0c0;
    stroke-width: 2.7;
    stroke-miterlimit: 10;
  }
  .st8 {
    fill: #ffffff;
    stroke: #003dc2;
    stroke-width: 2.7;
    stroke-miterlimit: 10;
  }
  .st9 {
    fill: url(#SVGID_3_);
  }
  .st10 {
    fill: url(#SVGID_4_) !important;
  }
  .st11 {
    fill: url(#SVGID_5_);
  }
  .st12 {
    fill: url(#SVGID_6_);
    animation: blend-in-out 3s linear;
    animation-iteration-count: infinite;
  }
  .st13 {
    opacity: 0.5;
    fill: url(#SVGID_7_);
  }
  .st14 {
    opacity: 0.5;
    fill: url(#SVGID_8_);
  }
  .st15 {
    opacity: 0.5;
    fill: url(#SVGID_9_);
  }
  .st16 {
    opacity: 0.5;
    fill: url(#SVGID_10_);
  }
  .st17 {
    opacity: 0.5;
    fill: url(#SVGID_11_);
  }
  .st18 {
    opacity: 0.5;
    fill: url(#SVGID_12_);
  }
  .st19 {
    fill: none;
    stroke: #f59147;
    stroke-width: 2.7;
    stroke-miterlimit: 10;
  }
  .st20 {
    fill: none;
    stroke: #e5365e;
    stroke-width: 2.7;
    stroke-miterlimit: 10;
  }
  .st21 {
    opacity: 0.5;
    fill: url(#SVGID_13_);
  }
  .st22 {
    opacity: 0.5;
    fill: url(#SVGID_14_);
  }
  .st23 {
    opacity: 0.5;
    fill: url(#SVGID_15_);
  }
  .st24 {
    opacity: 0.5;
    fill: url(#SVGID_16_);
  }
  .st25 {
    opacity: 0.5;
    fill: url(#SVGID_17_);
  }
  .st26 {
    opacity: 0.5;
    fill: url(#SVGID_18_);
  }
  .st27 {
    fill: url(#SVGID_19_);
    stroke: #003dc2;
    stroke-width: 2.7;
    stroke-miterlimit: 10;
  }
  .st28 {
    fill: url(#SVGID_20_);
  }
  .st29 {
    fill: #5ae0c0;
  }
  .st30 {
    fill: url(#SVGID_21_);
  }
  .st31 {
    fill: url(#SVGID_22_);
  }
  .st32 {
    fill: url(#SVGID_23_);
  }
  .st33 {
    fill: url(#SVGID_24_);
  }
  .st34 {
    fill: url(#SVGID_25_);
  }
  .st35 {
    fill: url(#SVGID_26_);
  }
  .st36 {
    fill: url(#SVGID_27_);
  }
  .st37 {
    fill: url(#SVGID_28_);
  }
  .st38 {
    fill: url(#SVGID_29_);
  }
  .st39 {
    fill: url(#SVGID_30_);
  }
  .st40 {
    fill: url(#SVGID_31_);
  }
  .st41 {
    fill: url(#SVGID_32_);
  }
  .st42 {
    fill: url(#SVGID_33_);
  }
  .st43 {
    fill: url(#SVGID_34_);
    stroke: #5ae0c0;
    stroke-width: 2.7;
    stroke-miterlimit: 10;
  }
  .st44 {
    fill: url(#SVGID_35_);
    stroke: #5ae0c0;
    stroke-width: 2.7;
    stroke-miterlimit: 10;
  }
  .st45 {
    fill: url(#SVGID_36_);
    stroke: #5ae0c0;
    stroke-width: 2.7;
    stroke-miterlimit: 10;
  }
  .st46 {
    fill: url(#SVGID_37_);
    stroke: #5ae0c0;
    stroke-width: 2.7;
    stroke-miterlimit: 10;
  }
  .st47 {
    fill: url(#SVGID_38_);
    stroke: #5ae0c0;
    stroke-width: 2.7;
    stroke-miterlimit: 10;
  }
  .st48 {
    fill: url(#SVGID_39_);
    stroke: #5ae0c0;
    stroke-width: 2.7;
    stroke-miterlimit: 10;
  }
  .st49 {
    fill: none;
  }
  .st50 {
    fill: url(#SVGID_40_);
  }

  #SVGID_11_ stop:last-child {
    animation: blend-in-out 3s linear infinite;
    animation-delay: random() + s;
  }
}

@keyframes blend-in-out {
  0% {
    stop-opacity: 0;
  }
  50% {
    stop-opacity: 1;
  }
  100% {
    stop-opacity: 0;
  }
}

@media screen and (max-width: 768px) {
  // Mobile Screens
  #illustration {
    height: 20vh;
    position: relative;

    svg {
      width: 95%;
    }
  }
}
