.popup {
  position: fixed;
  top: 0;
  left: 100%;
  opacity: 0;
  z-index: 9;
  background-color: var(--background-color);
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  padding: 0% 15% 10% 15%;
  margin-top: 113px;
  transition: left var(--transition-speed), opacity var(--transition-speed);

  h2 {
    margin-top: 5vh;
    margin-bottom: 2vh;
    color: var(--color-primary);
  }

  h3 {
    font-weight: bold;
  }

  &.active {
    left: 0;
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .popup {
    margin-top: 82px;
    padding-bottom: 82px;

    .title {
      font-size: 1.3rem;
    }
  }
}
