.footer {
  background-color: var(--text-color);
  color: var(--background-color);
  font-size: 18px;
  padding: 1%;

  .row {
    height: 100%;

    .logo-wrapper {
      width: 50%;
      margin: auto;
      svg {
        .st2 {
          fill: var(--background-color) !important;
        }
      }
    }

    p {
      width: 100%;
      margin-bottom: 0;
    }

    a {
      color: var(--background-color);
      width: 100%;
      display: block;

      &:hover {
        text-decoration: none !important;
        color: var(--color-primary) !important;
        cursor: pointer !important;
      }
    }
  }
}

@media screen and(max-width: 768px) {
  .footer {
    padding: 1% 5%;
    .row {
      .logo-wrapper {
        width: 80%;
      }

      [class*="col-"] {
        padding-top: 5%;
      }
    }
  }
}
