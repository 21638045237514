.contact {
  .frame {
    width: 100%;
    height: 40vh;
    .map {
      border: 0;
      padding: 0;
      position: relative;
      top: 50%;
      height: 100%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      object-fit: cover;
    }

    &:after {
      content: "";
      width: 100%;
      height: 50px;
      background-color: var(--background-color);
      position: absolute;
      left: 0;
      top: -50px;
    }
  }

  p {
    margin-bottom: 0;
  }

  .action-text {
    color: var(--text-color);
    transition: all var(--transition-speed);

    &:hover {
      text-decoration: none;
      color: $colorPrimary;
    }
  }

  .formular {
    form {
      border-top: 3px solid var(--text-color);
      border-bottom: 3px solid var(--text-color);
      fieldset {
        position: relative;

        label {
          position: absolute;
          top: 15px;
          left: 8px;
          color: var(--text-color);
          transform: translate3d(0, 0, 0);
          transition: all 0.2s ease-in-out;
        }
        input,
        textarea,
        input:-webkit-autofill {
          display: block;
          width: 100%;
          height: 3em;
          padding: 22px 8px 10px;
          border-bottom: 1px solid var(--text-color);
          border-right: none;
          border-left: none;
          font-weight: 400;
          line-height: 1;
          background-color: transparent !important;
          color: var(--text-color);
          transition: all var(--transition-speed);

          &:hover + label {
            color: $colorPrimary;
          }
          &:focus {
            outline: 0;
          }
          // &[data-empty="true"]:valid + label,
          &:not(:required) + label,
          &:valid + label,
          &:focus + label {
            color: $colorPrimary;
            font-weight: 700;
            font-size: 1rem;
            transform: translate3d(0, -15px, 0);
          }

          &:not([value=""]):not(textarea):invalid + label {
            color: rgb(255, 0, 0);
            font-weight: 700;
            font-size: 1rem;
            transform: translate3d(0, -15px, 0);
          }

          &#message {
            height: 20vh;
          }
        }
      }
    }

    .agree {
      float: left;
      [type="checkbox"]:not(:checked),
      [type="checkbox"]:checked {
        position: absolute;
        left: 0;
        opacity: 0.01;
      }
      [type="checkbox"]:not(:checked) + label,
      [type="checkbox"]:checked + label {
        position: relative;
        padding-left: 2.3em;
        font-size: 1.05em;
        line-height: 1.7;
        cursor: pointer;
      }

      /* checkbox aspect */
      [type="checkbox"]:not(:checked) + label:before,
      [type="checkbox"]:checked + label:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 1.4em;
        height: 1.4em;
        border: 1px solid var(--text-color);
        background: transparent;
        -webkit-transition: all 0.275s;
        transition: all 0.275s;
      }

      /* checked mark aspect */
      [type="checkbox"]:not(:checked) + label:after,
      [type="checkbox"]:checked + label:after {
        content: "✕";
        position: absolute;
        left: 0;
        top: 0;
        width: 1.4em;
        height: 1.4em;
        color: #5ae0c0;
        line-height: 0;
        transition: all 0.2s;
        text-align: center;
        padding: 0.8em 0%;
      }

      /* checked mark aspect changes */
      [type="checkbox"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0) rotate(45deg);
        transform: scale(0) rotate(45deg);
      }

      [type="checkbox"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1) rotate(0);
        transform: scale(1) rotate(0);
      }

      /* Disabled checkbox */
      [type="checkbox"]:disabled:not(:checked) + label:before,
      [type="checkbox"]:disabled:checked + label:before {
        box-shadow: none;
        border-color: #bbb;
        background-color: #e9e9e9;
      }

      [type="checkbox"]:disabled:checked + label:after {
        color: #777;
      }

      [type="checkbox"]:disabled + label {
        color: #aaa;
      }

      /* Accessibility */
      [type="checkbox"]:checked:focus + label:before,
      [type="checkbox"]:not(:checked):focus + label:before {
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 0 6px rgba(203, 34, 237, 0.2);
      }
    }

    .required {
      font-style: italic;
    }

    .information {
      margin-left: auto;
      width: fit-content;
      font-size: 1.2rem;

      a {
        cursor: pointer;
      }
    }

    .confirm {
      height: 10vh;
      &:hover {
        .text-small {
          color: $colorPrimary;
        }
        .divider-animation {
          &:after {
            width: 50%;
            background-color: $colorPrimary;
          }
        }
      }
      p {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 100%;
        text-align: right;

        &.note {
          border-radius: 5px;
          padding: 10px;
          color: white;
          right: -150%;
          transition: right ease-in-out var(--transition-speed);
          &.success {
            background-color: #008000;
          }

          &.error {
            background-color: #800000;
          }
          &.active {
            right: 0;
          }
        }
      }

      .divider-animation:after {
        left: 0;
        content: "";
        position: absolute;
        z-index: 1;
        height: 3px;
        width: 20%;
        top: 50%;
        transform: translateY(-50%);
        transition: width 0.2s, color 0.1s;
        background-color: white;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .contact {
    .formular {
      padding-top: 5vh !important;

      .form-check {
        input {
          width: 27px;
          height: 27px;
        }
      }

      .information {
        font-size: 1rem;
      }
    }
  }
}
