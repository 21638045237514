.projects {
  .row {
    margin-left: -15px ;

    .text-medium {
      margin-bottom: 0;
      font-size: 1.5rem;
      margin-top: 1rem;
    }
    .text-small {
      font-size: 18px;
      display: none;
      transition: display $transitionSpeed;
    }

    .bi {
      fill: var(--text-color);
      top: 30%;
      position: absolute;
      transform: rotate(0deg);
      transition: transform $transitionSpeed, fill $transitionSpeed;
    }

    .col-10 {
      align-self: center;
    }

    .divider {
      width: 95%;
      height: 1px;
      background-color: var(--text-color);
      margin: 0;
    }

    &.active {
      .text-medium {
      }
      .text-small {
        display: block !important;
        padding-top: 1rem;
      }

      .bi {
        fill: $colorPrimary;
        transform: rotate(180deg);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .projects {
    .row {
      margin-left: 0;
      .text-medium {
        font-size: 1rem;
      }

      .text-small {
        font-size: 0.75rem;
      }
    }
  }
}
