.navbar {
  position: fixed !important;
  top: 0;
  background-color: var(--background-color);
  height: 113px;
  width: 100vw;
  z-index: 1000;
  padding: 22px !important;
  font-size: 1.125rem;
  transition: background-color var(--transition-speed);

  .navbar-brand {
    height: 100%;
    padding: 0;

    img {
      height: 100%;
      width: auto;
    }
  }

  .collapse-button {
    display: none;
  }

  .navbar-nav {
    flex-direction: unset;
    .nav-item {
      padding: 0 22px;

      a {
        color: var(--text-color);

        &:hover {
          color: var(--color-primary);
        }
      }

      &.language {
        font-weight: bold;
        .dropdown-option {
          position: absolute;
          opacity: 0;
          transition: var(--transition-speed);
          &.open {
            opacity: 1;
          }
          &:hover {
            color: var(--color-primary);
          }
        }
      }

      .mode-switch {
        height: 0;
        width: 0;
        visibility: hidden;
        + label {
          cursor: pointer;
          text-indent: -9999px;
          width: 4rem;
          height: 2rem;
          background: #000;
          border: 1px solid white;
          border-radius: 100px;
          position: relative;
          top: 10%;

          &:after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translate(-100%, -50%);
            left: 50%;
            width: 32%;
            height: 64%;
            background: #fff;
            border: 1px solid white;
            border-radius: 90px;
            transition: 0.3s;
          }
        }

        &:hover + label:after {
          transform: translate(0%, -50%);
          background: black;
        }

        &:checked {
          + label:after {
            transform: translate(0%, -50%);
            background: black;
          }

          &:hover + label:after {
            transform: translate(-100%, -50%);
            background: #fff;
          }
        }
      }
      a:hover {
        // a {
        color: var(--color-primary);
        // }
      }
    }
  }
}

// $color: rgba(white, 0.5);

@media only screen and (max-width: 768px,) {
  .navbar {
    height: 83px;
    padding: 0px !important;

    .navbar-brand {
      padding: 3% !important;
    }

    .collapse-button {
      padding: 3% !important;
      display: block;
    }
    .navbar-nav {
      display: block;
      height: calc(var(--inner-height, 1vh) * 100);
      width: 100vw;
      left: 100%;
      top: 83px;
      opacity: 0;
      position: absolute;
      backdrop-filter: blur(10px);
      background-color: var(--navbar-color);
      transition: left var(--transition-speed), opacity var(--transition-speed), background-color var(--transition-speed);

      &.open {
        left: 0;
        opacity: 1;
      }

      .nav-item {
        font-size: 1.85rem;
        padding: 10px 22px;
        font-weight: 700;

        .dropdown-option {
          bottom: 4rem;
        }
      }
      .bottom {
        position: absolute;
        bottom: 15vh;
        &.right {
          right: 0;
        }
      }
    }
  }
}
