#emphasis {
  .st0 {
    fill: none;
    stroke: #003dc2;
    stroke-linecap: square;
    stroke-miterlimit: 10;
  }
  .st1 {
    fill: none;
    stroke: #5ae0c0;
    stroke-linecap: square;
    stroke-miterlimit: 10;
  }
  .st2 {
    fill: none;
    stroke: #000000;
    stroke-linecap: square;
    stroke-miterlimit: 10;
  }
  .st3 {
    fill: none;
    stroke: #5ae0c0;
    stroke-linecap: square;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st4 {
    fill: none;
    stroke: #5ae0c0;
    stroke-width: 0.5;
    stroke-linecap: square;
    stroke-miterlimit: 10;
  }
  .st5 {
    fill: #ffffff;
    stroke: #003dc2;
    stroke-linecap: square;
    stroke-miterlimit: 10;
  }
  .st6 {
    fill: none;
  }
  .st7 {
    fill: #003dc2;
  }
  .st8 {
    fill: #ffffff;
  }
  .st9 {
    fill: #5ae0c0;
  }
  .st10 {
    fill: #ffffff;
    stroke: #003dc2;
    stroke-width: 3.5;
    stroke-linecap: square;
    stroke-miterlimit: 10;
  }
  .st11 {
    fill: none;
    stroke: #003dc2;
    stroke-width: 3.5;
    stroke-linecap: square;
    stroke-miterlimit: 10;
  }
  .st12 {
    fill: none;
    stroke: #5ae0c0;
    stroke-width: 2.7;
    stroke-linecap: square;
    stroke-miterlimit: 10;
  }
  .st13 {
    fill: none;
    stroke: #ffffff;
    stroke-linecap: square;
    stroke-linejoin: round;
  }
  .st14 {
    fill: none;
    stroke: #ffffff;
    stroke-linecap: square;
  }
}
